import React, { useState, useEffect, useRef, useMemo } from "react";
import { checkThumbVisible, compact } from "./utils";
import { useStaticQuery, graphql } from "gatsby";
import Video from "./Video";
import * as classes from "./Carousel.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import BackgroundImage from "gatsby-background-image";
import useCarouselSwipe from "./useCarouselSwipe";

const Carousel = props => {
  const { defaultVideothumb } = useStaticQuery(graphql`
    {
      defaultVideothumb: file(name: { eq: "video-default-thumbnail" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [x, setX] = useState(0);

  const thumbnailsRef = useRef(null);
  const carouselRef = useRef(null);

  // Calculate slides
  const slides = props.mediaItems.map((item, index) => {
    // Check if item is a video
    if (item.videoUrl) {
      return (
        <Video style={{ transform: `translateX(${x}%)` }} src={item.videoUrl} />
      );
    }

    if (!item || !item.path || !item.path.childImageSharp) {
      throw new Error(`Invalid image in file '${props.file}'`);
    }
    return (
      <BackgroundImage
        tag="div"
        className={classes.slide}
        key={index}
        fluid={item.path.childImageSharp.fluid}
        style={{ transform: `translateX(${x}%)` }}
      ></BackgroundImage>
    );
  });

  // Calculate subtitles
  const subtitles = useMemo(() => {
    return props.mediaItems.map(img => {
      return img.subtitle;
    });
  }, [props.mediaItems]);

  // Calculate thumbnails
  const thumbnails = props.mediaItems.map((item, index) => {
    const isCurrent = index * -100 === x;
    let path = item.videoUrl ? item["thumbnail_path"] : item.path;
    if (!path) {
      path = defaultVideothumb;
    }
    return (
      <div
        id={`wrapper-${index}`}
        key={path.childImageSharp.fluid.src + index}
        className={`${classes.thumbWrapper} ${
          isCurrent ? classes.thumbnailActive : null
        }`}
      >
        <BackgroundImage
          onClick={() => updateX(index * -100)}
          tag="div"
          className={`${classes.thumbnail}`}
          key={isCurrent ? classes.thumbnailActive : "nope"}
          fluid={path.childImageSharp.fluid}
        ></BackgroundImage>
      </div>
    );
  });

  const checkAndScroll = newX => {
    if (props.mediaItems.length === 1) {
      return;
    }
    const index = Math.abs(newX) / 100;
    const visibilitycheck = checkThumbVisible(index);
    if (visibilitycheck.visible === false) {
      thumbnailsRef.current.scrollBy(Math.ceil(visibilitycheck.ammount), 0);
    }
  };

  const updateX = newX => {
    setX(newX);
    checkAndScroll(newX);
  };

  const previous = () => {
    const newX = x < 0 ? x + 100 : (slides.length - 1) * -100;
    setX(newX);
    checkAndScroll(newX);
  };

  const next = () => {
    const newX = x !== (slides.length - 1) * -100 ? x - 100 : 0;
    setX(newX);
    checkAndScroll(newX);
  };

  useCarouselSwipe(carouselRef, next, previous);

  if (props.mediaItems.length === 0) {
    return null;
  }
  return (
    <>
      {/* Slides */}
      <div className={classes.Carousel} ref={carouselRef}>
        {slides}

        {/* Arrows */}

        {props.mediaItems.length < 2 ? null : (
          <>
            <button className={classes.previousButton} onClick={previous}>
              <FontAwesomeIcon size="sm" icon={faChevronLeft} />
            </button>
            <button className={classes.nextButton} onClick={next}>
              <FontAwesomeIcon size="sm" icon={faChevronRight} />
            </button>
          </>
        )}
      </div>

      {/* Current subtitle */}
      {compact(subtitles).length > 0 && (
        <p
          style={{
            minHeight: "1.4rem",
            maxWidth: "800px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {subtitles[-(x / 100)]}
        </p>
      )}

      {/* Thumbs */}
      {props.mediaItems.length > 1 ? (
        <div className={classes.buttonsUnder}>
          <div ref={thumbnailsRef} className={classes.thumbnails}>
            {thumbnails}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Carousel;
